import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'
import { HTMLContent } from '../components/Content'
import Layout from '../components/Layout'

export const FaqPageTemplate = ({ content, title }) => {
  return (
    <Layout>
      <section className="hero is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <h1 className="title">{title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <HTMLContent className="is-size-5" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

FaqPageTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const FaqPage = ({ data }) => {
  const { markdownRemark } = data
  const { html, frontmatter } = markdownRemark

  return <FaqPageTemplate content={html} title={frontmatter.title} />
}

FaqPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FaqPage

export const faqPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
